.equipment {
    background-image: linear-gradient(
        to left,
        rgba(243, 238, 234, 0),
        rgba(248, 251, 251, 0.3)
      ),
      url("../../assets/landingmedicequipment.jpg");
  
    background-repeat: no-repeat;
    background-size: cover;
  
    height: auto;
    /* background-position: center;
    background-attachment: fixed; */
  }