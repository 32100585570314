.physio {
     background-image: linear-gradient(
         to left,
         rgba(243, 238, 234, 0),
         rgba(248, 251, 251, 0.500)
       ),
       url("../../assets/phsyiolanding.jpg");
   
     background-repeat: no-repeat;
     background-size: cover;
   
     height: auto;
     background-position: center;
     /* background-attachment: fixed; */
   }



   /* ///media query */

   /* @media only screen and (max-width: 375px) {
    .physio {
      width: 100%;
      background-image: linear-gradient(
          to left,
          rgba(243, 238, 234, 0),
          rgba(230, 242, 244,0.500)
        ),
        url("https://i.ibb.co/xgg1Wp8/phsyio.jpg");
      height: auto;
  
      background-size: contain;
      background-size: cover;
  
      background-size: 100% 75%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  @media only screen and (min-width: 380px) and (max-width: 540px) {
    .physio {
      width: 100%;
      background-image: linear-gradient(
          to left,
          rgba(243, 238, 234, 0),
          rgba(230, 242, 244, 0.500)
        ),
        url("https://i.ibb.co/xgg1Wp8/phsyio.jpg");
      height: auto;
  
      background-size: contain;
      background-size: cover;
      background-position: center;
      background-size: auto 90%;
  
      background-repeat: no-repeat;
    }
  }

  @media only screen and (min-width: 447px) and (max-width: 767px) {
    .physio {
      width: 100%;
      background-image: linear-gradient( 
          to left,
          rgba(243, 238, 234, 0),
          rgba(230, 242, 244, 0.500)
        ),
        url("https://i.ibb.co/xgg1Wp8/phsyio.jpg");
      height: auto;
  
      background-size: contain;
      background-size: cover;
  
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  @media only screen and (min-width: 540px) and (max-width: 725px) {
    .physio {
      width: 100%;
      background-image: linear-gradient(
          to left,
          rgba(243, 238, 234, 0),
          rgba(230, 242, 244, 0.500)
        ),
        url("https://i.ibb.co/xgg1Wp8/phsyio.jpg");
      height: auto;
  
      background-size: contain;
      background-size: cover;
  
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  @media only screen and (min-width: 725px) and (max-width: 1000px) {
    .physio {
      width: 100%;
      background-image: linear-gradient(
          to left,
          rgba(243, 238, 234, 0),
          rgba(230, 242, 244, 0.500)
        ),
        url("https://i.ibb.co/xgg1Wp8/phsyio.jpg");
      height: auto;
  
      background-size: contain;
      background-size: cover;
  
      background-size: 100% 90%;
      background-position: center;
      background-repeat: no-repeat;
    }
  } */