.caregiver {
    background-image: linear-gradient(
        to left,
        rgba(243, 238, 234, 0),
        rgba(248, 251, 251, 0.650)
      ),
      url("../../assets/caregiverlanding.png");
  
    background-repeat: no-repeat;
    background-size: cover;
  
    height: auto;
    /* background-position: center;
    background-attachment: fixed; */
  }


/* media query
 */

 /* @media only screen and (max-width: 375px) {
  .caregiver {
    width: 100%;
    background-image: linear-gradient(
        to left,
        rgba(243, 238, 234, 0),
        rgba(230, 242, 244, 0.650)
      ),
      url("https://i.ibb.co/GWvRxvN/caregiver.png");
    height: auto;

    background-size: contain;
    background-size: cover;

    background-size: 100% 75%;

    background-repeat: no-repeat;
  }
}


@media only screen and (min-width: 380px) and (max-width: 540px) {
  .caregiver {
    width: 100%;
    background-image: linear-gradient(
        to left,
        rgba(243, 238, 234, 0),
        rgba(230, 242, 244, 0.650)
      ),
      url("https://i.ibb.co/GWvRxvN/caregiver.png");
    height: auto;

    background-size: contain;
    background-size: cover;

    background-size: auto 90%;

    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 447px) and (max-width: 767px) {
  .caregiver {
    width: 100%;
    background-image: linear-gradient( 
        to left,
        rgba(243, 238, 234, 0),
        rgba(230, 242, 244, 0.650)
      ),
      url("https://i.ibb.co/GWvRxvN/caregiver.png");
    height: auto;

    background-size: contain;
    background-size: cover;

    background-size: auto 100%;

    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 540px) and (max-width: 725px) {
  .caregiver {
    width: 100%;
    background-image: linear-gradient(
        to left,
        rgba(243, 238, 234, 0),
        rgba(230, 242, 244, 0.650)
      ),
      url("https://i.ibb.co/GWvRxvN/caregiver.png");
    height: auto;

    background-size: contain;
    background-size: cover;

    background-size: auto 100%;

    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 725px) and (max-width: 1000px) {
  .caregiver {
    width: 100%;
    background-image: linear-gradient(
        to left,
        rgba(243, 238, 234, 0),
        rgba(230, 242, 244, 0.650)
      ),
      url("https://i.ibb.co/GWvRxvN/caregiver.png");
    height: auto;

    background-size: contain;
    background-size: cover;

    background-size: 100% 90%;

    background-repeat: no-repeat;
  }
} */