 .blog{

    font-family: 'Merriweather', serif;
}
 /* .blog{

   
    font-family: 'Roboto Mono', monospace;
     } */

 